import {
  Body,
  Controller,
  FileInterceptor,
  FilesInterceptor,
  Get,
  injectNestClient,
  Param,
  Post,
  UploadedFile,
  UploadedFiles,
  UseInterceptors,
} from 'nest-client';

/* tslint:disable:no-unused-variable */

@Controller('file')
export class FileService {
  baseUrl: string;

  constructor(baseUrl: string) {
    injectNestClient(this, {
      baseUrl,
      allowNonRestMethods: true,
    });
    this.baseUrl = baseUrl;
  }

  @Post('single')
  @UseInterceptors(FileInterceptor('file'))
  postSingleFile(
    @UploadedFile() _file: File,
    @Body('token') _token: string,
  ): Promise<string> {
    return undefined as any;
  }

  @Post('multiple')
  @UseInterceptors(FilesInterceptor('files'))
  postMultipleFiles(
    @UploadedFiles() _files: File[],
    @Body('token') _token: string,
  ): Promise<string[]> {
    return undefined as any;
  }

  @Post('string')
  postString(
    @Body('content') _content: string,
    @Body('mime') _mime: string | undefined,
    @Body('token') _token: string,
  ): Promise<string> {
    return undefined as any;
  }

  @Post('dataUrl')
  postDataUrl(
    @Body('dataUrl') _dataUrl: string,
    @Body('token') _token: string,
  ): Promise<string> {
    return undefined as any;
  }

  @Get(':multihash')
  async get(@Param('multihash') _multihash: string) {
    return undefined as any;
  }

  @Get('exist/:multihash')
  async exist(@Param('multihash') _multihash: string): Promise<boolean> {
    return undefined as any;
  }

  toFileUrl(src: string): string {
    if (src && src.startsWith('/file/')) {
      return this.baseUrl + src;
    }
    return src;
  }
}

/* tslint:enable:no-unused-variable */
