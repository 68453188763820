import { SubmitConsole } from './api';
import { argumentToData } from './helpers';
import { ConsoleName, Identity } from './types';

// tslint:disable-next-line: ban-types
function functionToData(func: Function): any {
  if (!func) {
    return;
  }
  return {
    name: func.name,
    length: func.length,
    toString: func.toString(),
  };
}

function isStrict(): boolean {
  // @ts-ignore
  // tslint:disable-next-line: no-invalid-this
  return !this;
}

function argumentsToData(args: IArguments): IArguments {
  const data = {} as IArguments;
  data.length = args.length;
  if (!isStrict()) {
    data.callee = functionToData(args.callee);
  }
  for (let i = 0; i < args.length; i++) {
    data[i] = argumentToData(args[i]);
  }
  return data;
}

// tslint:disable:no-console
export const log = console.log.bind(console);
export const debug = console.debug.bind(console);
export const error = console.error.bind(console);
// tslint:enable:no-console

export let originalConsole = console;
export let wrappedConsole = console;

export function wrapConsole(state: Identity) {
  wrappedConsole = {} as any;
  Object.entries(console).forEach(([name, method]) => {
    wrappedConsole = {
      ...wrappedConsole,
      [name]() {
        method.apply(console, arguments);
        SubmitConsole({
          token: state.token,
          user_id: state.user_id,
          app_id: state.app_id,
          captured_console: {
            name: name as ConsoleName,
            arguments: argumentsToData(arguments),
          },
        }).catch(e => {
          error('failed to submit captured console:', e);
        });
      },
    };
  });
  console = wrappedConsole;
}

export function unWrapConsole() {
  console = originalConsole;
}
