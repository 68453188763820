import { Body, Controller, injectNestClient, Post } from 'nest-client';
import { Primus } from 'typestub-primus';
import {
  AttemptCancelTaxiGroup,
  AttemptChangeTaxiGroupDeptTime,
  AttemptClaimPromoCode,
  AttemptCreateTaxiGroup,
  AttemptFindMatch,
  AttemptGetChatList,
  AttemptGetChatRoomData,
  AttemptGetCreditAmount,
  AttemptGetCurrentMatch,
  AttemptGetOwnProfileForChatBot,
  AttemptGetSelfProfile,
  AttemptGetUserPlace,
  AttemptHasName,
  AttemptJoinTaxiGroup,
  AttemptLeaveTaxiGroup,
  AttemptSaveUserPlace,
  AttemptSendMessageToTaxiGroup,
  AttemptSetName,
  AttemptSubscribeChatroomUpdate,
  AttemptSubscribeNotice,
  AttemptUpdateProfile,
  Call as CallType,
  CallInput,
  CancelSubscribe,
  FindNearByMatch,
  GetProductList,
  PurchaseCredit,
  Subscribe as SubscribeType,
} from './types';

export interface IPrimus extends Primus {
  send(command: string, data: any, cb?: (data: any) => void): void;
}

let primus: IPrimus;
let pfs: Array<(primus: IPrimus) => void> = [];

export function usePrimus(f: (primus: IPrimus) => void): void {
  if (primus) {
    f(primus);
    return;
  }
  pfs.push(f);
}

let coreService: CoreService;

@Controller('core')
export class CoreService {
  constructor(baseUrl: string) {
    injectNestClient(this, {
      baseUrl,
    });
  }

  @Post('Call')
  async Call<C extends CallType>(
    @Body() _body: CallInput<C>,
  ): Promise<C['Out']> {
    return undefined as any;
  }
}

export function startAPI(
  options:
    | {
        localhost: string;
      }
    | {
        mode: 'local' | 'test' | 'prod';
      }
    | {
        baseUrl: string;
      },
) {
  const baseUrl: string = (() => {
    if ('baseUrl' in options) {
      return options.baseUrl;
    }
    if ('localhost' in options) {
      return `http://${options.localhost}:16080`;
    }
    switch (options.mode) {
      case 'local':
        return 'http://localhost:16080';
      case 'test':
        return 'https://taxi.fduat.com';
      case 'prod':
        return 'https://taxi.fdapp.cc';
      default:
        throw new Error(
          `Failed to resolve baseUrl, unknown mode: '${options.mode}'`,
        );
    }
  })();
  if (typeof window === 'undefined') {
    coreService = new CoreService(baseUrl);
    return;
  }
  const w = window as any;
  primus = new w.Primus(baseUrl);

  pfs.forEach(f => f(primus));
  pfs = [];

  primus.on('close', () => {
    console.log('disconnected with server');
  });
  primus.on('open', () => {
    console.log('connected with server');
  });

  return primus;
}

export function Call<C extends CallType>(
  CallType: C['CallType'],
  Type: C['Type'],
  In: C['In'],
): Promise<C['Out']> {
  const callInput: CallInput<C> = {
    CallType,
    Type,
    In,
  };
  if (coreService) {
    return coreService.Call<C>(callInput);
  }
  return new Promise((resolve, reject) => {
    usePrimus(primus => {
      primus.send('Call', callInput, data => {
        if ('error' in data) {
          reject(data);
          return;
        }
        resolve(data);
      });
    });
  });
}

export function CancelSubscribe(
  In: CancelSubscribe['In'],
): Promise<CancelSubscribe['Out']> {
  return Call<CancelSubscribe>('Command', 'CancelSubscribe', In);
}

export function AttemptSetName(
  In: AttemptSetName['In'],
): Promise<AttemptSetName['Out']> {
  return Call<AttemptSetName>('Command', 'AttemptSetName', In);
}

export function AttemptSaveUserPlace(
  In: AttemptSaveUserPlace['In'],
): Promise<AttemptSaveUserPlace['Out']> {
  return Call<AttemptSaveUserPlace>('Command', 'AttemptSaveUserPlace', In);
}

export function AttemptCancelTaxiGroup(
  In: AttemptCancelTaxiGroup['In'],
): Promise<AttemptCancelTaxiGroup['Out']> {
  return Call<AttemptCancelTaxiGroup>('Command', 'AttemptCancelTaxiGroup', In);
}

export function AttemptJoinTaxiGroup(
  In: AttemptJoinTaxiGroup['In'],
): Promise<AttemptJoinTaxiGroup['Out']> {
  return Call<AttemptJoinTaxiGroup>('Command', 'AttemptJoinTaxiGroup', In);
}

export function AttemptCreateTaxiGroup(
  In: AttemptCreateTaxiGroup['In'],
): Promise<AttemptCreateTaxiGroup['Out']> {
  return Call<AttemptCreateTaxiGroup>('Command', 'AttemptCreateTaxiGroup', In);
}

export function AttemptSendMessageToTaxiGroup(
  In: AttemptSendMessageToTaxiGroup['In'],
): Promise<AttemptSendMessageToTaxiGroup['Out']> {
  return Call<AttemptSendMessageToTaxiGroup>(
    'Command',
    'AttemptSendMessageToTaxiGroup',
    In,
  );
}

export function AttemptLeaveTaxiGroup(
  In: AttemptLeaveTaxiGroup['In'],
): Promise<AttemptLeaveTaxiGroup['Out']> {
  return Call<AttemptLeaveTaxiGroup>('Command', 'AttemptLeaveTaxiGroup', In);
}

export function AttemptChangeTaxiGroupDeptTime(
  In: AttemptChangeTaxiGroupDeptTime['In'],
): Promise<AttemptChangeTaxiGroupDeptTime['Out']> {
  return Call<AttemptChangeTaxiGroupDeptTime>(
    'Command',
    'AttemptChangeTaxiGroupDeptTime',
    In,
  );
}

export function AttemptUpdateProfile(
  In: AttemptUpdateProfile['In'],
): Promise<AttemptUpdateProfile['Out']> {
  return Call<AttemptUpdateProfile>('Command', 'AttemptUpdateProfile', In);
}

export function AttemptClaimPromoCode(
  In: AttemptClaimPromoCode['In'],
): Promise<AttemptClaimPromoCode['Out']> {
  return Call<AttemptClaimPromoCode>('Command', 'AttemptClaimPromoCode', In);
}

export function PurchaseCredit(
  In: PurchaseCredit['In'],
): Promise<PurchaseCredit['Out']> {
  return Call<PurchaseCredit>('Command', 'PurchaseCredit', In);
}

export function AttemptHasName(
  In: AttemptHasName['In'],
): Promise<AttemptHasName['Out']> {
  return Call<AttemptHasName>('Query', 'AttemptHasName', In);
}

export function AttemptGetUserPlace(
  In: AttemptGetUserPlace['In'],
): Promise<AttemptGetUserPlace['Out']> {
  return Call<AttemptGetUserPlace>('Query', 'AttemptGetUserPlace', In);
}

export function AttemptGetCurrentMatch(
  In: AttemptGetCurrentMatch['In'],
): Promise<AttemptGetCurrentMatch['Out']> {
  return Call<AttemptGetCurrentMatch>('Query', 'AttemptGetCurrentMatch', In);
}

export function FindNearByMatch(
  In: FindNearByMatch['In'],
): Promise<FindNearByMatch['Out']> {
  return Call<FindNearByMatch>('Query', 'FindNearByMatch', In);
}

export function AttemptGetOwnProfileForChatBot(
  In: AttemptGetOwnProfileForChatBot['In'],
): Promise<AttemptGetOwnProfileForChatBot['Out']> {
  return Call<AttemptGetOwnProfileForChatBot>(
    'Query',
    'AttemptGetOwnProfileForChatBot',
    In,
  );
}

export function AttemptFindMatch(
  In: AttemptFindMatch['In'],
): Promise<AttemptFindMatch['Out']> {
  return Call<AttemptFindMatch>('Query', 'AttemptFindMatch', In);
}

export function AttemptGetChatRoomData(
  In: AttemptGetChatRoomData['In'],
): Promise<AttemptGetChatRoomData['Out']> {
  return Call<AttemptGetChatRoomData>('Query', 'AttemptGetChatRoomData', In);
}

export function AttemptGetChatList(
  In: AttemptGetChatList['In'],
): Promise<AttemptGetChatList['Out']> {
  return Call<AttemptGetChatList>('Query', 'AttemptGetChatList', In);
}

export function AttemptGetSelfProfile(
  In: AttemptGetSelfProfile['In'],
): Promise<AttemptGetSelfProfile['Out']> {
  return Call<AttemptGetSelfProfile>('Query', 'AttemptGetSelfProfile', In);
}

export function AttemptGetCreditAmount(
  In: AttemptGetCreditAmount['In'],
): Promise<AttemptGetCreditAmount['Out']> {
  return Call<AttemptGetCreditAmount>('Query', 'AttemptGetCreditAmount', In);
}

export function GetProductList(
  In: GetProductList['In'],
): Promise<GetProductList['Out']> {
  return Call<GetProductList>('Query', 'GetProductList', In);
}

export interface SubscribeOptions<T> {
  onError: (err: any) => void;
  onEach: (Out: T) => void;
  onReady?: () => void;
}

export interface SubscribeResult {
  cancel: () => void;
}

export function Subscribe<C extends SubscribeType>(
  Type: C['Type'],
  In: C['In'],
  options: SubscribeOptions<C['Out']>,
): SubscribeResult {
  if (coreService) {
    throw new Error('Subscribe is not supported on node.js client yet');
  }
  const callInput: CallInput<C> = {
    CallType: 'Subscribe',
    Type,
    In,
  };
  let cancelled = false;
  const res: SubscribeResult = { cancel: () => (cancelled = true) };
  usePrimus(primus => {
    primus.send('Call', callInput, data => {
      if ('error' in data) {
        options.onError(data);
        return;
      }
      if (cancelled) {
        return;
      }
      const { id } = data;
      primus.on(id, data => {
        if (!cancelled) {
          options.onEach(data as any);
        }
      });
      res.cancel = () => {
        cancelled = true;
        primus.send('CancelSubscribe', { id });
      };
      if (options.onReady) {
        options.onReady();
      }
    });
  });
  return res;
}

export function AttemptSubscribeChatroomUpdate(
  In: AttemptSubscribeChatroomUpdate['In'],
  options: SubscribeOptions<AttemptSubscribeChatroomUpdate['Out']>,
): SubscribeResult {
  return Subscribe<AttemptSubscribeChatroomUpdate>(
    'AttemptSubscribeChatroomUpdate',
    In,
    options,
  );
}
export function AttemptSubscribeNotice(
  In: AttemptSubscribeNotice['In'],
  options: SubscribeOptions<AttemptSubscribeNotice['Out']>,
): SubscribeResult {
  return Subscribe<AttemptSubscribeNotice>(
    'AttemptSubscribeNotice',
    In,
    options,
  );
}
