import { Injectable } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

enum TSAPIResponseResult {
  s = 's',
  f = 'f',
}

enum YNEnum {
  y = 'y',
  n = 'n',
}

enum ContactMethods {
  'tel',
  'whatsapp',
  'wechat',
  'facebook',
  'instagram',
  'telegram',
  'facebook_messenger',
  'twitter',
  'email',
  'other',
}

export interface LatLonType {
  lat: number;
  lon: number;
  url?: SafeResourceUrl;
}

export interface TSAPIResponse {
  service: string;
  result: TSAPIResponseResult;
  msg?: any | null;
}

export interface User {
  raw_id: number;
  coreuser_displayname: string;
  avatar?: string;
  coreuser_gender?: string; // f,m,o
  coreuser_sys_passcode?: string;
  coreuser_tel?: string;
  coreuser_email?: string;
}

export interface ContactMethod {
  method:
    | 'tel'
    | 'whatsapp'
    | 'wechat'
    | 'facebook'
    | 'instagram'
    | 'telegram'
    | 'facebook_messenger'
    | 'twitter'
    | 'email'
    | 'other';
  contact_id: string;
}

export interface ShopServiceProvider {
  name: string;
  selected?: boolean;
}

export interface ShopService {
  name: string;
  providers?: string[];
  selected?: boolean;
}

export interface ShopServiceTimeslotOpenhr {
  morning: string[];
  afternoon: string[];
  night: string[];
  midnight: string[];
}

export interface ShopServiceTimeslotWeek {
  week: number;
  openhr: ShopServiceTimeslotOpenhr;
}

export interface ShopServiceTimeslot {
  weeks: ShopServiceTimeslotWeek[];
  oph: boolean /* Open Public Hoildays */;
  sba: number /* Start Booking After */;
  hoilday?: string[] | number[];
}

export interface ShopServices {
  services: ShopService[];
  timeslots: ShopServiceTimeslot;
  remarks?: string;
}

export interface Shop {
  raw_id: number;
  shop_type_main: string;
  shop_type_sub: string;
  shop_type_sub_en?: string;
  shop_type_sub_tc?: string;
  shop_icon?: string;
  shop_icon_url?: string;
  shop_images?: string[];
  shop_name_tc?: string;
  shop_name_sc?: string;
  shop_name_en?: string;
  shop_name_icon?: string;
  shop_district?: number;
  shop_latitude?: number;
  shop_longitude?: number;
  shop_addr_tc?: string;
  shop_addr_sc?: string;
  shop_addr_en?: string;
  shop_tel?: string | number;
  shop_website?: string;
  shop_24hrs: YNEnum;
  shop_promote?: string;
  shop_services?: ShopServices;
  shop_allow_booking: YNEnum;
  shop_shortdesc?: string;
  district_name_tc?: string;
  district_name_en?: string;
  is_enable: YNEnum;
  created_timestamp?: number;
  admin_remarks?: string;
}

export interface BookingRecord {
  raw_id: number;
  core_user_id: number;
  shop_id: number;
  shop_name_tc: string;
  sb_user_name: string;
  sb_user_tel: string;
  sb_service: string;
  sb_book_date: string;
  sb_book_time: string;
  sb_remarks?: string;
  sb_status: string;
  created_timestamp?: string | Date;
}

export interface TaxiMatching {
  group_id: string;
  taxi_from_name: string;
  taxi_from_lat: number;
  taxi_from_lon: number;
  taxi_to_name: string;
  taxi_to_lat: number;
  taxi_to_lon: number;
  taxi_meettime: number | string | Date;
  taxi_status: 'grouping' | 'closed' | 'finished';
  created_timestamp: number | string | Date;
  admin_remarks?: string;
  taxi_applicants?: Applicant[];
}

export interface Applicant {
  user_id: string;
  role: 'creator' | 'player';
  coreuser_gender?: string;
  coreuser_displayname: string;
  avatar?: string;
}

export interface ToDoAfterLoggedIn {
  fun: string;
  data: any;
  from: string;
}

export interface FromToInfo {
  from_lat: number;
  from_lon: number;
  from_addr: string;
  to_lat: number;
  to_lon: number;
  to_addr: string;
}

export interface ChatMessages {
  msg_type: 'text' | 'latlon' | 'voice' | 'photo';
  sender_core_user_id: string | number;
  sender_core_user_icon: string;
  sender_core_user_displayname: string;
  receiver_core_user_id?: string | number;
  created_timestamp: string | number | Date;
  chat_message: string | LatLonType;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalValuesService {
  public appid = 15;

  public inTab: string = '';

  public loginDisplayname = '';

  public CoreLink = 'https://core.freedomdragon.ltd/';
  public CoreSer = this.CoreLink + 'core_ser.php';
  public CorePicSer = this.CoreLink + 'core_ser_getpic.php';

  public adminMode = false;
  public tmp_productCreateSelectedTags = [];

  public chattingWithRoomId: string | number = 0;
  public isChatting = false;

  public toDoAfterLoggedin: ToDoAfterLoggedIn | null = null;

  public sharedDiscountedProducts = [];

  public mapPageInfo = {
    lat: 0,
    lon: 0,
    address: '',
    isAllowPinCurrentLocation: false,
    isAllowClick: false,
    isGetAddressWhenInit: false,
    isGetAddressWhenClick: false,
    isAddressEditable: false,
    isSetToGlobalAndStorage: false,
    hasDoneButton: false,
  };

  public tmpData: any = null;

  constructor() {}
}
