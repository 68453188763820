import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'chat-room',
    loadChildren: './chat-room/chat-room.module#ChatRoomPageModule',
  },
  {
    path: 'chat-bot',
    loadChildren: './chat-bot/chat-bot.module#ChatBotPageModule',
  },
  {
    path: 'tab-chat-list',
    loadChildren: './tab-chat-list/tab-chat-list.module#TabChatListPageModule',
  },
  { path: 'agree', loadChildren: './agree/agree.module#AgreePageModule' },
  { path: 'agree2', loadChildren: './agree2/agree2.module#Agree2PageModule' },
  { path: 'about', loadChildren: './about/about.module#AboutPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  {
    path: 'claim-promo-code',
    loadChildren:
      './claim-promo-code/claim-promo-code.module#ClaimPromoCodePageModule',
  },
  {
    path: 'buy-credit',
    loadChildren: './buy-credit/buy-credit.module#BuyCreditPageModule',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
