import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import {
  ToastController,
  NavController,
  ModalController,
  NavParams,
} from '@ionic/angular';
import { TsAPIService } from '../ts-api.service';
import { GlobalValuesService, User } from '../global-values.service';
import { callApi } from '../../helpers/api';
import { AttemptReportContent } from '../../domain/report/api';
import { ReasonCodeType, reasons, ReasonType } from '../../domain/report/types';
import { state } from '../../global/state';
import { showToast } from '../../helpers/lib';
import { nextId } from '../../domain/utils';
import { ReportComplainPageParams } from './report-complain.types';

@Component({
  selector: 'app-report-complain',
  templateUrl: './report-complain.page.html',
  styleUrls: ['./report-complain.page.scss'],
})
export class ReportComplainPage implements OnInit {
  user: User = null;
  prbs = reasons.map(reason => ({
    name: reason.Chin,
    code: reason.Code,
    turnon: false,
  }));
  complain_reason = '';

  report_id: Promise<string>;

  constructor(
    public navCtrl: NavController,
    public TsAPI: TsAPIService,
    public GlobalValues: GlobalValuesService,
    public storage: Storage,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    public navParams: NavParams,
  ) {}

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss({ dismissed: true });
  }

  async sendReport() {
    const params = this.navParams.data as ReportComplainPageParams;
    const { content_id, content_type } = params;
    if (!content_type) {
      console.error('missing content_type');
      return;
    }
    if (!content_id) {
      console.error('missing content_id');
      return;
    }
    if (!this.report_id) {
      this.report_id = nextId();
    }
    const report_id = await this.report_id;
    const reason = this.prbs.find(p => p.turnon);
    const reason_type: ReasonCodeType = reason ? reason.code : 'Other';
    if (reason_type === 'Other' && !this.complain_reason.trim()) {
      showToast({ message: '請提供原因' });
      return;
    }
    callApi({
      action: '發送回報',
      p: AttemptReportContent({
        token: state.token,
        report: {
          report_id,
          content_type,
          content_id,
          reason_type,
          reason_remark: this.complain_reason,
        },
      }),
      show_success_toast: true,
      onSuccess: () => this.dismiss(),
    });
  }
}
