export type Gender = 'f' | 'm' | 'o';
export type Applicant = {
  user_id: string;
  role: 'creator' | 'player';
  coreuser_gender?: Gender;
  coreuser_displayname: string;
  avatar?: string;
};
export type TaxiMatching = {
  group_id: string;
  taxi_from_name: string;
  taxi_from_lat: number;
  taxi_from_lon: number;
  taxi_to_name: string;
  taxi_to_lat: number;
  taxi_to_lon: number;
  taxi_meettime: number | string | Date;
  taxi_status: 'grouping' | 'closed' | 'finished';
  created_timestamp: number | string | Date;
  admin_remarks?: string;
  taxi_applicants?: Applicant[];
};
export type MatchPoint = {
  addr: string;
  lat: number;
  lon: number;
};
export type LatLonType = {
  lat: number;
  lon: number;
  url?: string;
};
export type MessageType =
  | {
      msg_type: 'text' | 'voice' | 'photo';
      chat_message: string;
    }
  | {
      msg_type: 'latlon';
      chat_message: LatLonType;
    };
export type ChatMessage = {
  msg_type: 'text' | 'latlon' | 'voice' | 'photo';
  sender_core_user_id: string | number;
  sender_core_user_icon?: string;
  sender_core_user_displayname?: string;
  receiver_core_user_id?: string | number;
  created_timestamp: string | number | Date;
  chat_message: string | LatLonType;
};
export type ChatRoomData = {
  taxi_from_name: string;
  taxi_from_lat: number;
  taxi_from_lon: number;
  taxi_to_name: string;
  taxi_meettime: number | string | Date;
  admin_user_id: string;
  admin_name?: string;
};
export type ChatroomUpdate =
  | {
      type: 'room';
      room: ChatRoomData;
    }
  | {
      type: 'chat';
      chatMessage: ChatMessage;
    };
export type ChatListItem = {
  group_id: string;
  room_icon?: string;
  from_addr: string;
  to_addr: string;
  meettime?: number;
  room_lastmsg?: string;
};
export type SelfProfile = {
  coreuser_displayname?: string;
  avatar?: string;
  coreuser_gender?: Gender;
};
export type ProductType = {
  productId: string;
  title: string;
  description: string;
  price: string;
};
export type ReceiptType = {
  transactionId: string;
  receipt: string;
  signature: string;
  productType: string;
};

export type CancelSubscribe = {
  CallType: 'Command';
  Type: 'CancelSubscribe';
  In: { id: string };
  Out:
    | {
        Success: false;
        Reason: 'no active session matched' | 'no active channel matched';
      }
    | { Success: true };
};
export type AttemptSetName = {
  CallType: 'Command';
  Type: 'AttemptSetName';
  In: {
    name: string;
  } & { token: string };
  Out: { Success: false; Reason: 'InvalidToken' } | { Success: true };
};
export type AttemptSaveUserPlace = {
  CallType: 'Command';
  Type: 'AttemptSaveUserPlace';
  In: {
    place: {
      name: string;
      lat: number;
      lng: number;
      addr: string;
    };
  } & { token: string };
  Out: { Success: false; Reason: 'InvalidToken' } | { Success: true };
};
export type AttemptCancelTaxiGroup = {
  CallType: 'Command';
  Type: 'AttemptCancelTaxiGroup';
  In: {
    group_id: string;
  } & { token: string };
  Out:
    | {
        Success: false;
        Reason:
          | 'InvalidToken'
          | 'GroupNotFound'
          | 'NotAdmin'
          | 'HasOtherMembers';
      }
    | { Success: true };
};
export type AttemptJoinTaxiGroup = {
  CallType: 'Command';
  Type: 'AttemptJoinTaxiGroup';
  In: {
    group_id: string;
  } & { token: string };
  Out:
    | {
        Success: false;
        Reason: 'InvalidToken' | 'GroupNotFound' | 'GroupAlreadyFull';
      }
    | { Success: true };
};
export type AttemptCreateTaxiGroup = {
  CallType: 'Command';
  Type: 'AttemptCreateTaxiGroup';
  In: {
    group_id: string;
    endPoint: MatchPoint;
    startPoint: MatchPoint;
    deptTime: number;
  } & { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' | 'OutOfCredit' }
    | { Success: true };
};
export type AttemptSendMessageToTaxiGroup = {
  CallType: 'Command';
  Type: 'AttemptSendMessageToTaxiGroup';
  In: {
    group_id: string;
    receiver_user_id?: string;
    message: MessageType;
  } & { token: string };
  Out:
    | {
        Success: false;
        Reason:
          | 'InvalidToken'
          | 'GroupNotFound'
          | 'SenderNotInGroup'
          | 'ReceiverNotInGroup'
          | 'UnsupportedMessageType';
      }
    | { Success: true };
};
export type AttemptLeaveTaxiGroup = {
  CallType: 'Command';
  Type: 'AttemptLeaveTaxiGroup';
  In: {
    group_id: string;
  } & { token: string };
  Out: { Success: false; Reason: 'InvalidToken' } | { Success: true };
};
export type AttemptChangeTaxiGroupDeptTime = {
  CallType: 'Command';
  Type: 'AttemptChangeTaxiGroupDeptTime';
  In: {
    group_id: string;
    deptTime: number;
  } & { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' | 'GroupNotFound' | 'NotAdmin' }
    | { Success: true };
};
export type AttemptUpdateProfile = {
  CallType: 'Command';
  Type: 'AttemptUpdateProfile';
  In: {
    profile: {
      coreuser_displayname?: string;
      avatar?: string;
      coreuser_gender?: Gender;
    };
  } & { token: string };
  Out: { Success: false; Reason: 'InvalidToken' } | { Success: true };
};
export type AttemptClaimPromoCode = {
  CallType: 'Command';
  Type: 'AttemptClaimPromoCode';
  In: {
    promo_code: string;
  } & { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' | 'AlreadyUsed' | 'InvalidCode' }
    | { Success: true };
};
export type PurchaseCredit = {
  CallType: 'Command';
  Type: 'PurchaseCredit';
  In: {
    user_id: string;
    receipt: ReceiptType;
  };
  Out: { Success: false; Reason: 'InvalidReceipt' } | { Success: true };
};

export type Command =
  | CancelSubscribe
  | AttemptSetName
  | AttemptSaveUserPlace
  | AttemptCancelTaxiGroup
  | AttemptJoinTaxiGroup
  | AttemptCreateTaxiGroup
  | AttemptSendMessageToTaxiGroup
  | AttemptLeaveTaxiGroup
  | AttemptChangeTaxiGroupDeptTime
  | AttemptUpdateProfile
  | AttemptClaimPromoCode
  | PurchaseCredit;

export type AttemptHasName = {
  CallType: 'Query';
  Type: 'AttemptHasName';
  In: { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' }
    | ({ Success: true } & {
        has_name: boolean;
      });
};
export type AttemptGetUserPlace = {
  CallType: 'Query';
  Type: 'AttemptGetUserPlace';
  In: {
    place_name: string;
  } & { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' }
    | ({ Success: true } & {
        location?: {
          lat: number;
          lng: number;
          addr: string;
        };
      });
};
export type AttemptGetCurrentMatch = {
  CallType: 'Query';
  Type: 'AttemptGetCurrentMatch';
  In: { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' }
    | ({ Success: true } & {
        match?: TaxiMatching;
      });
};
export type FindNearByMatch = {
  CallType: 'Query';
  Type: 'FindNearByMatch';
  In: {
    startPoint: MatchPoint;
  } & { token: string | undefined | null };
  Out:
    | { Success: false; Reason: 'InvalidToken' }
    | ({ Success: true } & {
        nearbyMatches: TaxiMatching[];
      });
};
export type AttemptGetOwnProfileForChatBot = {
  CallType: 'Query';
  Type: 'AttemptGetOwnProfileForChatBot';
  In: { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' }
    | ({ Success: true } & {
        user_icon?: string;
        user_displayname?: string;
      });
};
export type AttemptFindMatch = {
  CallType: 'Query';
  Type: 'AttemptFindMatch';
  In: {
    endPoint: MatchPoint;
    startPoint: MatchPoint;
    deptTime: number;
  } & { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' }
    | ({ Success: true } & {
        youMatched: TaxiMatching[];
      });
};
export type AttemptGetChatRoomData = {
  CallType: 'Query';
  Type: 'AttemptGetChatRoomData';
  In: {
    group_id: string;
  } & { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' | 'GroupNotFound' }
    | ({ Success: true } & {
        room: ChatRoomData;
        chatMessages: ChatMessage[];
      });
};
export type AttemptGetChatList = {
  CallType: 'Query';
  Type: 'AttemptGetChatList';
  In: { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' }
    | ({ Success: true } & {
        chatList: ChatListItem[];
      });
};
export type AttemptGetSelfProfile = {
  CallType: 'Query';
  Type: 'AttemptGetSelfProfile';
  In: { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' }
    | ({ Success: true } & {
        profile: SelfProfile;
        promo_code?: string;
        credit: number;
      });
};
export type AttemptGetCreditAmount = {
  CallType: 'Query';
  Type: 'AttemptGetCreditAmount';
  In: { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' }
    | ({ Success: true } & {
        credit: number;
      });
};
export type GetProductList = {
  CallType: 'Query';
  Type: 'GetProductList';
  In: { token: string | undefined | null };
  Out:
    | { Success: false; Reason: 'InvalidToken' }
    | ({ Success: true } & {
        products: ProductType[];
      });
};

export type Query =
  | AttemptHasName
  | AttemptGetUserPlace
  | AttemptGetCurrentMatch
  | FindNearByMatch
  | AttemptGetOwnProfileForChatBot
  | AttemptFindMatch
  | AttemptGetChatRoomData
  | AttemptGetChatList
  | AttemptGetSelfProfile
  | AttemptGetCreditAmount
  | GetProductList;

export type AttemptSubscribeChatroomUpdate = {
  CallType: 'Subscribe';
  Type: 'AttemptSubscribeChatroomUpdate';
  In: {
    group_id: string;
  } & { token: string };
  Out:
    | {
        Success: false;
        Reason: 'InvalidToken' | 'GroupNotFound' | 'UserNotInGroup';
      }
    | ({ Success: true } & ChatroomUpdate);
};
export type AttemptSubscribeNotice = {
  CallType: 'Subscribe';
  Type: 'AttemptSubscribeNotice';
  In: { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' }
    | ({ Success: true } & {
        notice: any;
      });
};

export type Subscribe = AttemptSubscribeChatroomUpdate | AttemptSubscribeNotice;

export type Call = Command | Query | Subscribe;

export interface CallInput<C extends Call = Call> {
  CallType: C['CallType'];
  Type: C['Type'];
  In: C['In'];
}

function checkCallType(_t: {
  CallType: 'Command' | 'Query' | 'Subscribe';
  Type: string;
  In: any;
  Out: any;
}) {
  /* static type check only */
}

checkCallType({} as Call);

export const app_id: 'ltd.freedomdragon.shareride' =
  'ltd.freedomdragon.shareride';
export const DefaultUserName: '小市民' = '小市民';
