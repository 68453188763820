import { showToast } from './lib';

export const genResultToast = (action: string) => {
  return {
    onSuccess: () => {
      return showToast({
        message: `已${action}`,
        duration: 2000,
      });
    },
    onFail: (reason: any) => {
      console.error(`failed to ${action}:`, reason);
      if (reason && reason.message) {
        // reason is an HttpException
        reason = reason.message;
      }
      return showToast({
        message: `未能${action}: ` + reason,
        duration: 5000,
      });
    },
  };
};

export type Result =
  | { Success: true }
  | { Success: false; Reason: string }
  | { Success: boolean };

export function showResultInToast<T extends Result>(
  action: string,
  p: Promise<T>,
  then?: (res: T) => void | Promise<void>,
  options?: 'skip_success',
): Promise<void> {
  const { onFail, onSuccess } = genResultToast(action);
  return p
    .then((res: Result) => {
      if (res.Success === true) {
        return Promise.all([
          options === 'skip_success' ? void 0 : onSuccess(),
          then ? then(res as T) : void 0,
        ]) as Promise<any>;
      } else {
        return onFail('Reason' in res ? res.Reason : 'unknown reason');
      }
    })
    .catch(onFail);
}
export function TODO(): any {
  throw new Error('not_impl');
}
