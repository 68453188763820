export type ReasonCodeType =
  | 'Nudity or pornography'
  | 'Violence'
  | 'Harassment'
  | 'Suicide or self-harm'
  | 'False news'
  | 'Spam'
  | 'Unauthorized sales'
  | 'Hate speech'
  | 'Terrorism'
  | 'Copyright infringement'
  | 'Other';
export type ReasonType = {
  Eng: string;
  Chin: string;
  Code: ReasonCodeType;
  Remark?: string;
};
export type NewReportType = {
  report_id: string;
  content_type: string;
  content_id: string;
  reason_type: ReasonCodeType;
  reason_remark?: string;
};
export type NewFeedbackType = {
  app_id: string;
  feedback_id: string;
  comment: string;
};
export type ReportType = NewReportType & {
  app_id: string;
  user_id: string;
};
export type FeedbackType = NewFeedbackType & {
  user_id?: string;
};

export type AttemptReportContent = {
  CallType: 'Command';
  Type: 'AttemptReportContent';
  In: { report: NewReportType } & { token: string };
  Out:
    | { Success: false; Reason: 'InvalidToken' | 'InvalidAppId' | 'Duplicated' }
    | { Success: true };
};
export type SendFeedBack = {
  CallType: 'Command';
  Type: 'SendFeedBack';
  In: { feedback: NewFeedbackType } & { token: string | undefined | null };
  Out:
    | { Success: false; Reason: 'InvalidToken' | 'InvalidAppId' | 'Duplicated' }
    | { Success: true };
};

export type Command = AttemptReportContent | SendFeedBack;

export type Query = never;

export type Subscribe = never;

export type Call = Command | Query | Subscribe;

export interface CallInput<C extends Call = Call> {
  CallType: C['CallType'];
  Type: C['Type'];
  In: C['In'];
}

function checkCallType(_t: {
  CallType: 'Command' | 'Query' | 'Subscribe';
  Type: string;
  In: any;
  Out: any;
}) {
  /* static type check only */
}

checkCallType({} as Call);

export const reasons: ReasonType[] = [
  {
    Code: 'Nudity or pornography',
    Eng: 'Nudity or pornography',
    Chin: '裸露或色情',
  },
  {
    Code: 'Violence',
    Eng: 'Violence',
    Chin: '暴力',
  },
  {
    Code: 'Harassment',
    Eng: 'Harassment',
    Chin: '騷擾',
  },
  {
    Code: 'Suicide or self-harm',
    Eng: 'Suicide or self-harm',
    Chin: '自殺或自殘',
  },
  {
    Code: 'False news',
    Eng: 'False news',
    Chin: '虛假新聞',
  },
  {
    Code: 'Spam',
    Eng: 'Spam',
    Chin: '垃圾訊息',
  },
  {
    Code: 'Unauthorized sales',
    Eng: 'Unauthorized sales',
    Chin: '未經授權銷售',
  },
  {
    Code: 'Hate speech',
    Eng: 'Hate speech',
    Chin: '仇恨言論',
  },
  {
    Code: 'Terrorism',
    Eng: 'Terrorism',
    Chin: '恐怖主義',
  },
  {
    Code: 'Copyright infringement',
    Eng: 'Copyright infringement',
    Chin: '侵犯版權',
  },
  {
    Code: 'Other',
    Eng: 'Other (please specify)',
    Chin: '其他 (請列明)',
  },
];
