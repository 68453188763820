import { Component } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TsAPIService } from './ts-api.service';
import { GlobalValuesService } from './global-values.service';
import { startAPIs } from '../domain/utils';
import { state } from '../global/state';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    public storage: Storage,
    private platform: Platform,
    public router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public TsAPI: TsAPIService,
    public GlobalValues: GlobalValuesService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    startAPIs(state);
    // startAPIs({ localhost: '192.168.1.13' });
    if (!state.hasAgreeTerm) {
      this.router.navigateByUrl('/agree');
    }
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.statusBar.styleLightContent();
        this.splashScreen.hide();
      }
    });
  }
}
