import { usePrimus } from './api';
import { startAPI as startAppAPI } from './api';
import { startAPI as startCoreAPI } from './core/api';
import { startAPI as startFileAPI } from './file/api';
import { startAPI as startReportAPI } from './report/api';
import { startAPI as startDebugAPI } from './debug/api';
import { wrapAll } from './debug/capture';
import { Identity } from './debug/types';

let acc = 0;

/**
 * TODO change to use device id, so can work in offline
 */
export function nextId(): Promise<string> {
  return new Promise<string>((resolve, _reject) => {
    usePrimus(primus =>
      primus.id(id => {
        acc++;
        resolve(id + '-' + acc);
      }),
    );
  });
}

export type id = string | number;

export function startAPIs(
  state: ({ mode: 'local' | 'test' | 'prod' } | { localhost: string }) &
    Identity,
) {
  startCoreAPI(state);
  startReportAPI(state);
  startFileAPI(state);
  startAppAPI(state);
  if ('mode' in state && state.mode === 'local') {
    return; // do not need to wrap console error to server when testing locally
  }
  startDebugAPI(state);
  wrapAll(state);
}
