/// <reference types="@types/dom-mediacapture-record" />
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import {
  AlertController,
  LoadingController,
  Events,
  Platform,
} from '@ionic/angular';
import { AlertOptions, AlertButton } from '@ionic/core';
import { EventHandler } from '@ionic/angular/dist/providers/events';
import { Storage } from '@ionic/storage';
import * as querystring from 'query-string';
import { GlobalValuesService } from './global-values.service';
import { detectMobilePlatform } from '@beenotung/tslib/platform';
import { createDefer } from '@beenotung/tslib/async/defer';
import {
  selectImage,
  selectAudio,
  fileToBase64String,
} from '@beenotung/tslib/file';
import {
  base64,
  compressMobilePhoto,
  dataURItoMimeType,
  dataURItoBlob,
} from '@beenotung/tslib/image';
import { format_time_code } from '@beenotung/tslib/format';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File as NativeFile } from '@ionic-native/file/ngx';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { later } from '@beenotung/tslib/async/wait';
import { Result, then } from '@beenotung/tslib/result';
import { i18n } from '../global/i18n';
import { showToast } from '../helpers/lib';

export type AddressData = {
  display_name: string;
  address: { suburb: null | string };
};

export type BaseVoiceFile = {
  isRecording: boolean;
};
export type CordovaVoiceFile = BaseVoiceFile & {
  type: 'cordova';
  tmp_filename: string;
  mediaObject: MediaObject;
  src: string;
  base64?: string;
};
export type WebVoiceFile = BaseVoiceFile & {
  type: 'web';
  mediaRecorder: MediaRecorder;
  // mimeType: string;
  // blobs: Blob[];
  filePromise: Promise<File>;
  file?: File;
};
export type InputVoiceFile = {
  type: 'input';
  filePromise: Promise<File>;
  file?: File;
};
export type RecordingVoiceFile = CordovaVoiceFile | WebVoiceFile;
export type VoiceFile = InputVoiceFile | RecordingVoiceFile;

export type MediaCallback = {
  /** @deprecated use base64 for compression */
  onFile?: (file: File) => void; // raw input file
  onBase64?: (base64: string) => void; // compressed dataURI
};
export type MediaOption = {
  capture?: boolean;
} & MediaCallback;

@Injectable({
  providedIn: 'root',
})
export class TsAPIService {
  constructor(
    private http: HttpClient,
    private events: Events,
    private push: Push,
    private storage: Storage,
    private alertCtrl: AlertController,
    private platform: Platform,
    private geolocation: Geolocation,
    private GlobalValues: GlobalValuesService,
    private loadingCtrl: LoadingController,
    private camera: Camera,
    private media: Media,
    private file: NativeFile,
  ) {
    console.log('Hello TsAPIService');
  }

  public doPNFun() {
    this.push
      .hasPermission()
      .then((res: any) => {
        if (!res.isEnabled) {
          console.log('native push: no permission 1');
          return;
        }
        console.log('native push: has permission 2');

        this.push
          .createChannel({
            id: 'share ride',
            description: 'share ride 1',
            // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
            importance: 3,
          })
          .then(() => console.log('Channel created'));

        const options: PushOptions = {
          android: { senderID: '316036362825' },
          ios: {
            alert: 'true',
            badge: true,
            sound: 'false',
            clearBadge: true,
          },
          windows: {},
          browser: {},
        };
        const pushObject: PushObject = this.push.init(options);
        pushObject.on('registration').subscribe((data: any) => {
          this.storage.set('device_token', data.registrationId);
        });
        pushObject.on('notification').subscribe((notification: any) => {
          console.log('ionic, noti=' + JSON.stringify(notification));
        });
        pushObject
          .on('error')
          .subscribe(error => console.error('Error with Push plugin', error));
      })
      .catch(e => {
        console.log('native push: error:', e);
      });
  }

  // FIXME only call this when needed
  public getCurrentLocation(): Promise<{ lat: number; lng: number }> {
    const p = this.geolocation.getCurrentPosition().then(resp => {
      const { latitude: lat, longitude: lng } = resp.coords;
      return { lat, lng };
    });
    p.catch(err => {
      if (err && err.message === 'User denied geolocation prompt') {
        // known error type
      } else {
        console.error('failed to get current position:', err);
      }
    });
    return p;
  }

  public tryGetCurrentLocation(): Promise<
    { lat: number; lng: number } | undefined
  > {
    const timeout = 2000;
    return new Promise<{ lat: number; lng: number } | undefined>(resolve => {
      setTimeout(() => resolve(), timeout);
      this.geolocation
        .getCurrentPosition({ timeout })
        .then(res =>
          resolve({
            lat: res.coords.latitude,
            lng: res.coords.longitude,
          }),
        )
        .catch(err => {
          console.error('failed to get current location:', err);
          resolve();
        });
    });
  }

  public loadAddressCore({ lat, lng }: { lat: number; lng: number }) {
    this.resolveOsmAddress({
      lat,
      lng,
    }).then(addr => {
      this.events.publish('ev:updateLatLng', {
        lat,
        lng,
        addr,
      });
    });
  }

  public createHTTPRequest(
    apiUrl: string,
    data: any = {},
    callback: (res: any) => void,
  ) {
    if (this.http === null) {
      return;
    }
    const body: string = querystring.stringify(data);
    return this.http
      .post(apiUrl, body, {
        headers: new HttpHeaders().set(
          'Content-Type',
          'application/x-www-form-urlencoded',
        ),
      })
      .subscribe(
        res => {
          callback(res);
        },
        error => {
          console.log(error);
        },
      );
  }

  postHTTPRequest({
    url,
    param,
    body,
  }: {
    url: string;
    param?: object;
    body?: any;
  }): Promise<any> {
    if (!this.http) {
      throw new Error('http is not set');
    }
    if (param) {
      url = url + '?' + querystring.stringify(param);
    }
    body = querystring.stringify(body || {});
    return this.http
      .post(url, body, {
        headers: new HttpHeaders().set(
          'Content-Type',
          'application/x-www-form-urlencoded',
        ),
      })
      .toPromise()
      .catch(err => {
        console.error('failed to post http', { url, body, err });
        return Promise.reject(err);
      });
  }

  public getDateArray(date: Date) {
    const todayMonthInt = date.getMonth() + 1;
    let todayMonthString = '' + todayMonthInt;
    if (todayMonthInt < 10) {
      todayMonthString = '0' + todayMonthInt;
    }
    const todayDateInt = date.getDate();
    let todayDateString = '' + todayDateInt;
    if (todayDateInt < 10) {
      todayDateString = '0' + todayDateInt;
    }
    let h = '' + date.getHours();
    if (date.getHours() < 10) {
      h = '0' + h;
    }
    let m = '' + date.getMinutes();
    if (date.getMinutes() < 10) {
      m = '0' + m;
    }
    let s = '' + date.getSeconds();
    if (date.getSeconds() < 10) {
      s = '0' + s;
    }
    return [
      date.getFullYear(),
      todayMonthString,
      todayDateString,
      h,
      m,
      s,
      date.getMilliseconds(),
    ];
  }

  public getTodayDateArray() {
    const todayTodayString = new Date();
    const todayMonthInt = todayTodayString.getMonth() + 1;
    let todayMonthString = '' + todayMonthInt;
    if (todayMonthInt < 10) {
      todayMonthString = '0' + todayMonthInt;
    }
    const todayDateInt = todayTodayString.getDate();
    let todayDateString = '' + todayDateInt;
    if (todayDateInt < 10) {
      todayDateString = '0' + todayDateInt;
    }
    return [
      todayTodayString.getFullYear(),
      todayMonthString,
      todayDateString,
      todayTodayString.getHours(),
      todayTodayString.getMinutes(),
      todayTodayString.getSeconds(),
      todayTodayString.getMilliseconds(),
    ];
  }

  public getDTID() {
    const dd = this.getTodayDateArray();
    return (
      dd[0] +
      '' +
      dd[1] +
      '' +
      dd[2] +
      '' +
      dd[3] +
      '' +
      dd[4] +
      '' +
      dd[5] +
      '' +
      dd[6] +
      Math.floor(Math.random() * 100 + 999)
    );
  }

  public getNowDateTime() {
    return this.getDateTimeString(new Date());
  }

  public getDateTimeString(date: Date) {
    return date.toLocaleString();
  }

  public formatTime(date: Date): string {
    const hour = date.getHours();
    const min = date.getMinutes();
    if (hour === 0) {
      return '上午12時' + min + '分';
    } else if (hour === 12) {
      return '中午' + min + '分';
    } else if (hour < 12) {
      return '上午' + hour + '時' + min + '分';
    } else if (hour > 12) {
      return '下午' + (hour - 12) + '時' + min + '分';
    }
    return date.toLocaleTimeString();
  }

  public getHumanReadableTimeDuration(dt: string | number) {
    const MILLISECOND = 1;
    const SECOND = MILLISECOND * 1000;
    const MINUTE = SECOND * 60;
    const HOUR = MINUTE * 60;
    const DAY = HOUR * 24;
    const WEEK = DAY * 7;
    const YEAR = 365.2425 * DAY;
    const MONTH = YEAR / 12;
    const DECADE = YEAR * 10;
    const CENTURY = YEAR * 100;
    let date: Date;
    if (typeof dt === 'string') {
      date = new Date(dt.replace(/-/g, '/'));
    } else {
      date = new Date(dt);
    }
    const now = new Date();
    const diff = date.getTime() - now.getTime();
    const absDiff = Math.abs(diff);
    const absDiffInDay = Math.floor(absDiff / DAY);

    if (date.getTime() < now.getTime()) {
      if (absDiff < HOUR) {
        return '已逾時' + Math.floor(absDiff / MINUTE) + '分鐘';
      }
      if (absDiff < DAY) {
        return '已逾時' + Math.floor(absDiff / HOUR) + '小時';
      }
      return '已逾時' + absDiffInDay + '日';
    } else {
      if (diff < MINUTE) {
        return '立即';
      }
      if (diff < HOUR * 1) {
        return Math.floor(diff / MINUTE) + '分鐘後';
      }
      if (diff < DAY) {
        return this.formatTime(date);
      }

      if (absDiffInDay === 2) {
        return '後日' + this.formatTime(date);
      }
      if (absDiffInDay === 1) {
        return '明日' + this.formatTime(date);
      }
      if (diff < YEAR) {
        return (
          date.getMonth() +
          1 +
          '月' +
          date.getDate() +
          '日' +
          this.formatTime(date)
        );
      }
      return (
        date.getFullYear() +
        '年' +
        (date.getMonth() + 1) +
        '月' +
        date.getDate() +
        '日' +
        this.formatTime(date)
      );
    }
  }

  public getHumanReadableDateTime(dt: string | number) {
    const MILLISECOND = 1;
    const SECOND = MILLISECOND * 1000;
    const MINUTE = SECOND * 60;
    const HOUR = MINUTE * 60;
    const DAY = HOUR * 24;
    const WEEK = DAY * 7;
    const YEAR = 365.2425 * DAY;
    const MONTH = YEAR / 12;
    const DECADE = YEAR * 10;
    const CENTURY = YEAR * 100;
    let date: Date;
    if (typeof dt === 'string') {
      date = new Date(dt.replace(/-/g, '/'));
    } else {
      date = new Date(dt);
    }
    const now = new Date();
    if (
      date.getFullYear() !== now.getFullYear() ||
      date.getTime() > now.getTime()
    ) {
      return (
        date.getFullYear() +
        '年' +
        (date.getMonth() + 1) +
        '月' +
        date.getDate() +
        '日' +
        this.formatTime(date)
      );
    }

    // same year
    const diff = now.getTime() - date.getTime();
    if (diff < 2 * MINUTE) {
      return '剛剛';
    }
    if (diff < HOUR) {
      return Math.floor(diff / MINUTE) + '分鐘前';
    }
    if (diff < DAY) {
      return Math.floor(diff / HOUR) + '小時前';
    }

    const diffInDay = Math.floor(diff / DAY);
    if (diffInDay === 2) {
      return '前日' + this.formatTime(date);
    }
    if (diffInDay === 1) {
      return '昨日' + this.formatTime(date);
    }
    if (diff < YEAR) {
      return (
        date.getMonth() +
        1 +
        '月' +
        date.getDate() +
        '日' +
        this.formatTime(date)
      );
    }
    return (
      date.getFullYear() +
      '年' +
      (date.getMonth() + 1) +
      '月' +
      date.getDate() +
      '日' +
      this.formatTime(date)
    );
  }

  public resolveOsmAddress({
    lat,
    lng,
    zoomLevel,
  }: {
    lat: number;
    lng: number;
    zoomLevel?: number;
  }): Promise<string> {
    return this.postHTTPRequest({
      url: 'https://nominatim.openstreetmap.org/reverse',
      param: {
        format: 'json',
        lat,
        lon: lng,
        zoom: zoomLevel || 18,
        addressdetails: 1,
        'accept-language': 'zh-hant',
      },
    }).then(latlngData => this.extractOsmAddress(latlngData));
  }

  public extractOsmAddress(addrData: AddressData): string {
    // console.log(JSON.stringify(addrData));
    let addr = addrData.display_name;
    if (addrData.display_name.indexOf(', ') !== -1) {
      const addrs = addrData.display_name.split(', ');
      addr = addrs[0];
      if (addr.indexOf('第') !== -1 && addr.indexOf('座') !== -1) {
        addr = addrs[1];
        if (addrData.address.suburb !== null) {
          addr = addrData.address.suburb + addr;
        }
      }
      if (addr.indexOf('House') !== -1) {
        addr = addrs[1];
        if (addrData.address.suburb !== null) {
          addr = addrData.address.suburb + addr;
        }
      }
      if (addr.indexOf('Tower') !== -1) {
        addr = addrs[1];
        if (addrData.address.suburb !== null) {
          addr = addrData.address.suburb + addr;
        }
      }
      if (
        addrData.display_name.indexOf('大窩坪') !== -1 &&
        addrData.display_name.indexOf('達康路') !== -1
      ) {
        addr = '城市大學宿舍' + addrs[0];
      }
    }
    return addr;
  }

  public publishEvent(topic: EventTopic) {
    this.events.publish(topic);
  }

  public subscribeEvent(topic: EventTopic, handler: EventHandler) {
    this.events.subscribe(topic, handler);
  }

  public unsubscribeEvent(topic: EventTopic, handler: EventHandler) {
    this.events.unsubscribe(topic, handler);
  }

  public isCordova(): boolean {
    return this.platform.is('cordova');
  }

  private shouldAskSource(): boolean {
    if (this.isCordova()) {
      return true;
    }
    const platform = detectMobilePlatform();
    switch (platform) {
      case 'Windows Phone':
      case 'Android':
      case 'iOS':
        return true;
      case 'unknown':
        return false;
    }
  }

  private selectPhotoSource(): Promise<{ fromCamera: boolean }> {
    return new Promise(async resolve => {
      const alert = await this.alertCtrl.create({
        header: '選擇來源',
        buttons: [
          {
            text: '拍照',
            handler: () => {
              resolve({ fromCamera: true });
            },
          },
          {
            text: '相簿',
            handler: () => {
              resolve({ fromCamera: false });
            },
          },
          { text: '取消', role: 'cancel' },
        ],
      });
      alert.present();
    });
  }

  private compressPhoto({
    base64: image,
    onBase64,
  }: {
    base64: base64;
    onBase64: (base64: string) => void; // compressed dataURI
  }) {
    compressMobilePhoto({ image }).then(base64 => onBase64(base64));
  }

  private async selectPhotoWithWeb({ capture, onFile, onBase64 }: MediaOption) {
    const files = await selectImage({ capture });
    files.forEach(file => {
      if (onFile) {
        onFile(file);
      }
      if (onBase64) {
        fileToBase64String(file).then(base64 =>
          this.compressPhoto({ base64, onBase64 }),
        );
      }
    });
  }

  private async selectPhotoWithCordova({
    capture,
    onFile,
    onBase64,
  }: MediaOption) {
    const source = capture
      ? this.camera.PictureSourceType.CAMERA
      : this.camera.PictureSourceType.PHOTOLIBRARY;
    const mimeType = 'image/jpeg';
    const options: CameraOptions = {
      quality: 50,
      correctOrientation: true,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: source,
    };
    this.camera.getPicture(options).then(
      imageUrl => {
        if (this.platform.is('android')) {
          if (imageUrl.indexOf('?') !== -1) {
            imageUrl = imageUrl.split('?')[0];
          }
        }
        let path = imageUrl.split('/');
        const fname = path[path.length - 1];
        path = imageUrl.replace('/' + fname, '');
        const file = new NativeFile();
        const lastModified = Date.now();
        if (onFile) {
          file
            .readAsArrayBuffer(path, fname)
            .then(bs =>
              onFile(new File([bs], fname, { lastModified, type: mimeType })),
            )
            .catch((e: TypeError) =>
              console.error('failed to read image file:', e.message),
            );
        }
        if (onBase64) {
          file
            .readAsDataURL(path, fname)
            .then(base64 => this.compressPhoto({ base64, onBase64 }))
            .catch((e: TypeError) =>
              console.error('failed to read image file:', e.message),
            );
        }
      },
      err => {
        console.error('failed to get picture:', err);
      },
    );
  }

  private ensureSelectedPhotoSource({
    capture,
  }: {
    capture?: boolean;
  }): Result<{ capture: boolean }> {
    if (typeof capture === 'boolean') {
      return { capture };
    }
    if (!this.shouldAskSource()) {
      return { capture: false };
    }
    return this.selectPhotoSource().then(({ fromCamera }) => ({
      capture: fromCamera,
    }));
  }

  public selectPhoto({ capture, onFile, onBase64 }: MediaOption) {
    then(this.ensureSelectedPhotoSource({ capture }), ({ capture }) => {
      if (this.isCordova()) {
        return this.selectPhotoWithCordova({ capture, onFile, onBase64 });
      } else {
        return this.selectPhotoWithWeb({ capture, onFile, onBase64 });
      }
    });
  }

  public startRecordingVoiceOnAndroid(): RecordingVoiceFile {
    const tmp_filename = 'voice_' + this.getDTID() + '.mp3';
    const file = this.media.create(
      this.file.externalRootDirectory + tmp_filename,
    );
    const src = this.file.externalRootDirectory + tmp_filename;
    file.startRecord();
    return {
      type: 'cordova',
      tmp_filename,
      mediaObject: file,
      src,
      isRecording: true,
    };
  }

  public async startRecordingVoiceIOS(): Promise<RecordingVoiceFile> {
    const tmp_filename = 'voice_' + this.getDTID() + '.m4a';
    await this.file.createFile(this.file.tempDirectory, tmp_filename, true);
    const file = this.media.create(
      this.file.tempDirectory.replace(/^file:\/\//, '') + tmp_filename,
    );
    const src = this.file.tempDirectory + tmp_filename;
    file.startRecord();
    return {
      type: 'cordova',
      tmp_filename,
      mediaObject: file,
      src,
      isRecording: true,
    };
  }

  public async startRecordingVoiceWeb(): Promise<RecordingVoiceFile> {
    if (!navigator.getUserMedia) {
      throw new Error('UserMedia is not supported');
    }
    return new Promise<RecordingVoiceFile>((resolve, reject) =>
      navigator.getUserMedia(
        { audio: true, video: false },
        stream => {
          const mimeType = 'audio/mp4';
          const mediaRecorder = new MediaRecorder(stream, { mimeType });
          const blobs: Blob[] = [];
          const fileDefer = createDefer<File, any>();
          const voiceFile: RecordingVoiceFile = {
            type: 'web',
            mediaRecorder,
            isRecording: false,
            // mimeType,
            // blobs,
            filePromise: fileDefer.promise,
          };
          mediaRecorder.onerror = event => {
            console.error('failed to record audio:', event.error);
          };
          mediaRecorder.onstop = () => {
            voiceFile.isRecording = false;
            const lastModified = Date.now();
            const file = new File(
              blobs,
              format_time_code(lastModified) + '.m4a',
              {
                type: mimeType,
                lastModified,
              },
            );
            voiceFile.file = file;
            fileDefer.resolve(file);
          };
          mediaRecorder.ondataavailable = event => {
            blobs.push(event.data);
          };
          mediaRecorder.start();
          voiceFile.isRecording = true;
          resolve(voiceFile);
        },
        error => {
          console.error('failed navigator.getUserMedia:', error);
          reject(error.message);
        },
      ),
    );
  }

  public selectAudioFile(): InputVoiceFile {
    const voiceFile: InputVoiceFile = {
      type: 'input',
      filePromise: (async () => {
        const files = await selectAudio({ multiple: false, capture: true });
        const file = files[0];
        if (!file) {
          throw new Error('user cancelled');
        }
        voiceFile.file = file;
        return file;
      })(),
    };
    return voiceFile;
  }

  public startRecordingVoice(): Result<VoiceFile> {
    if (this.platform.is('ios')) {
      return this.startRecordingVoiceIOS();
    } else if (this.platform.is('android')) {
      return this.startRecordingVoiceOnAndroid();
    } else {
      if (navigator.getUserMedia) {
        return this.startRecordingVoiceWeb();
      } else {
        return this.selectAudioFile();
      }
    }
  }

  public async stopRecordingVoiceWithCordova({
    voiceFile,
    onFile,
    onBase64,
  }: { voiceFile: CordovaVoiceFile } & MediaCallback) {
    voiceFile.mediaObject.stopRecord();
    voiceFile.isRecording = false;
    await later(100);
    const folder = this.platform.is('android')
      ? this.file.externalRootDirectory
      : this.file.tempDirectory;
    const lastModified = Date.now();
    const filename = voiceFile.tmp_filename;
    if (onFile) {
      this.file
        .readAsArrayBuffer(folder, filename)
        .then(bs => onFile(new File([bs], filename, { lastModified })))
        .catch(e => console.error('failed to read voice recording:', e));
    }
    if (onBase64) {
      this.file
        .readAsDataURL(folder, filename)
        .then(base64 => onBase64(base64))
        .catch(e => console.error('failed to read voice recording:', e));
    }
  }

  public stopRecordingVoiceWithWeb({
    voiceFile,
    onFile,
    onBase64,
  }: { voiceFile: WebVoiceFile } & MediaCallback) {
    voiceFile.filePromise.then(file => {
      if (onFile) {
        onFile(file);
      }
      if (onBase64) {
        fileToBase64String(file).then(base64 => onBase64(base64));
      }
    });
    voiceFile.mediaRecorder.stop();
  }

  /** @return base64 string */
  public stopRecordingVoice({
    voiceFile,
    onBase64,
    onFile,
  }: { voiceFile: RecordingVoiceFile } & MediaCallback) {
    if (voiceFile.type === 'web') {
      return this.stopRecordingVoiceWithWeb({ voiceFile, onFile, onBase64 });
    } else {
      return this.stopRecordingVoiceWithCordova({
        voiceFile,
        onFile,
        onBase64,
      });
    }
  }

  public showLoading(message = i18n.loading) {
    return this.loadingCtrl.create({ message }).then(loading => {
      loading.present();
      return loading;
    });
  }

  public async showAlert(options: AlertOptions) {
    const alert = await this.alertCtrl.create(options);
    alert.present();
    return alert;
  }

  public get cancelAlertButton(): AlertButton {
    return { text: i18n.alert.cancel, role: 'cancel' };
  }

  public confirmAlertButton(handler: () => void): AlertButton {
    return { text: i18n.alert.confirm, handler };
  }
}

export enum EventTopic {
  'loggedinout' = 'app:loggedinout',
}
