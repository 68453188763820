export function eventTargetToData(eventTarget: EventTarget | null) {
  if (!eventTarget) {
    return eventTarget;
  }
  const e = eventTarget as HTMLInputElement;
  return {
    id: e.id,
    name: e.name,
    dataset: e.dataset,
    tagName: e.tagName,
    className: e.className,
    value: e.value,
    type: e.type,
    accept: e.accept,
    pattern: e.pattern,
    checked: e.checked,
  };
}

export function eventToData(event: Event | string): any {
  if (!event || typeof event === 'string') {
    return event;
  }
  return {
    type: event.type,
    target: eventTargetToData(event.target),
    returnValue: event.returnValue,
    toString: event.toString(),
  };
}

export function errorToData(error: Error | undefined): any {
  if (!error) {
    return error;
  }
  const data: any = {
    name: error.name,
    message: error.message,
    stack: error.stack,
    toString: error.toString(),
  };
  Object.getOwnPropertyNames(error).forEach(
    key => (data[key] = error[key as keyof typeof error]),
  );
  return data;
}

export function argumentToData(argument: any) {
  if (!argument) {
    return argument;
  }
  if (argument instanceof Error) {
    return errorToData(argument);
  }
  if (argument instanceof EventTarget) {
    return eventTargetToData(argument);
  }
  if (argument instanceof Event) {
    return eventToData(argument);
  }
  return argument;
}
