import { FileService } from './file.service';

export let fileService: FileService;

export function startAPI(
  options:
    | {
        localhost: string;
      }
    | {
        mode: 'local' | 'test' | 'prod';
      }
    | {
        baseUrl: string;
      },
) {
  const baseUrl: string = (() => {
    if ('baseUrl' in options) {
      return options.baseUrl;
    }
    if ('localhost' in options) {
      return `http://${options.localhost}:50080`;
    }
    switch (options.mode) {
      case 'local':
        return 'http://localhost:50080';
      case 'test':
        return 'https://file.fduat.com';
      case 'prod':
        return 'https://file.freedomdragon.cc';
      default:
        throw new Error(
          `Failed to resolve baseUrl, unknown mode: '${options.mode}'`,
        );
    }
  })();
  fileService = new FileService(baseUrl);
}
