import { DefaultUserName } from '../domain/types';

export let i18n = {
  app_name: '一齊搭',
  loading: '載入中...',
  alert: {
    cancel: '取消',
    confirm: '確定',
  },
  home: {
    cancel_group: '取消組隊',
    enter_chatroom: '進入聊天室',
  },
  places: {
    home: '屋企',
    office: '公司',
  },
  map: {
    to_where: '想去邊度呀？',
    from_where: '喺邊度出發？',
    select_from_map: '從地圖中選擇',
    confirm_to: '確定目的地',
    confirm_from: '確定出發點',
  },
  chatroom: {
    upload_from_camera: '上載相機',
    upload_from_gallery: '上載相簿',
    send_photo: '發送相片',
    send_location: '分享位置',
    only_admin_can_change_time: ({ admin_name }: { admin_name: string }) =>
      `請通知管理員(${admin_name})更改時間`,
  },
  profile: {
    default_display_name: DefaultUserName || '小市民',
    gender: {
      f: '女生',
      m: '男生',
      o: '其他',
    },
  },
};
