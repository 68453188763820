import { genResultToast, Result } from './error';

export function callApi<T extends Result>(args: {
  action: string;
  p: Promise<T>;
  onSuccess?: (res: T) => void | Promise<void>;
  onFail?: (err: T | any) => void;
  show_success_toast?: boolean;
  skip_fail_toast?: boolean;
}) {
  const { onFail, onSuccess } = genResultToast(args.action);
  return args.p
    .then((res: Result) =>
      Promise.all<any>(
        res.Success === true
          ? [
              args.show_success_toast ? onSuccess() : [],
              args.onSuccess ? args.onSuccess(res as T) : [],
            ]
          : [
              onFail('Reason' in res ? res.Reason : 'unknown reason'),
              args.onFail ? args.onFail(res) : [],
            ],
      ),
    )
    .catch(err =>
      Promise.all<any>([
        args.skip_fail_toast ? [] : onFail(err),
        args.onFail ? args.onFail(err) : [],
      ]),
    );
}

export function logApiCall<T extends Result>(args: {
  action: string;
  p: Promise<T>;
}) {
  return callApi({
    ...args,
    show_success_toast: false,
    skip_fail_toast: true,
  });
}
