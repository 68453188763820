import { Body, Controller, injectNestClient, Post } from 'nest-client';
import {
  Call as CallType,
  CallInput,
  CheckToken,
  RequestPasscode,
  RequestToken,
} from './types';

let coreService: CoreService;

@Controller('core')
export class CoreService {
  constructor(baseUrl: string) {
    injectNestClient(this, {
      baseUrl,
    });
  }

  @Post('Call')
  async Call<C extends CallType>(
    @Body() _body: CallInput<C>,
  ): Promise<C['Out']> {
    return undefined as any;
  }
}

export function startAPI(
  options:
    | {
        localhost: string;
      }
    | {
        mode: 'local' | 'test' | 'prod';
      }
    | {
        baseUrl: string;
      },
) {
  const baseUrl: string = (() => {
    if ('baseUrl' in options) {
      return options.baseUrl;
    }
    if ('localhost' in options) {
      return `http://${options.localhost}:30080`;
    }
    switch (options.mode) {
      case 'local':
        return 'http://localhost:30080';
      case 'test':
        return 'https://core.fduat.com';
      case 'prod':
        return 'https://core.freedomdragon.cc';
      default:
        throw new Error(
          `Failed to resolve baseUrl, unknown mode: '${options.mode}'`,
        );
    }
  })();
  coreService = new CoreService(baseUrl);
}

export function Call<C extends CallType>(
  CallType: C['CallType'],
  Type: C['Type'],
  In: C['In'],
): Promise<C['Out']> {
  const callInput: CallInput<C> = {
    CallType,
    Type,
    In,
  };
  return coreService.Call<C>(callInput);
}

export function RequestPasscode(
  In: RequestPasscode['In'],
): Promise<RequestPasscode['Out']> {
  return Call<RequestPasscode>('Command', 'RequestPasscode', In);
}

export function RequestToken(
  In: RequestToken['In'],
): Promise<RequestToken['Out']> {
  return Call<RequestToken>('Command', 'RequestToken', In);
}

export function CheckToken(In: CheckToken['In']): Promise<CheckToken['Out']> {
  return Call<CheckToken>('Query', 'CheckToken', In);
}
