import { SubmitError } from './api';
import { error as console_error } from './capture-console';
import { errorToData, eventToData } from './helpers';
import { Identity } from './types';

const suppressErrorAlert = false;

export function wrapError(state: Identity) {
  window.onerror = (
    event: Event | string,
    source?: string,
    lineno?: number,
    colno?: number,
    error?: Error,
  ) => {
    SubmitError({
      token: state.token,
      user_id: state.user_id,
      app_id: state.app_id,
      captured_error: {
        event: eventToData(event),
        source,
        lineno,
        colno,
        error: errorToData(error),
      },
    }).catch(e => {
      console_error('failed to submit captured error:', e);
    });
    return suppressErrorAlert;
  };
}
