import { app_id } from '../domain/types';

const storageCache: any = {};
export let noop = () => {
  // place holder
};

function setStorage(key: string, value: string | null) {
  if (value) {
    storageCache[key] = value;
    localStorage.setItem(key, value);
  } else {
    delete storageCache[key];
    localStorage.removeItem(key);
  }
}

function getStorage(key: string): string {
  return storageCache[key] || localStorage.getItem(key) || '';
}

class State {
  get mode(): 'local' | 'test' | 'prod' {
    if (this.localDev) {
      return 'local';
    }
    if (this.dev) {
      return 'test';
    }
    return 'prod';
  }

  // TODO check if the session has expired
  get hasLogin(): boolean {
    return !!this.token && !!this.user_id;
  }

  get token(): string | undefined {
    return getStorage('token') || undefined;
  }

  set token(token: string) {
    setStorage('token', token);
  }

  get user_id(): string | undefined {
    return getStorage('user_id') || undefined;
  }

  set user_id(user_id: string) {
    setStorage('user_id', user_id);
  }

  app_id = app_id;

  get hasAgreeTerm(): boolean {
    return !!getStorage('has_agree_term');
  }

  set hasAgreeTerm(activate: boolean) {
    setStorage('has_agree_term', activate ? 'true' : '');
  }

  dev = false;

  localDev =
    this.dev &&
    (location.hostname === 'localhost' || location.hostname === '127.0.0.1');

  update = {
    settingsPage: noop,
  };

  logout() {
    this.token = '';
    this.user_id = '';
  }
}

export let state = new State();
